.wrapper {
  flex: 1;
  height: 100%;
}

.regionsContentWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 16px;
  position: relative;
  margin-top: 8px;
  margin-bottom: 16px;
}
.description {
  font-size: 14px;
  line-height: 17px;
}
.modalFooter {
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;
}
.alert {
  margin-top: auto;
}
.regions {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
.titleButtons {
  margin-left: auto;
}
