@import 'src/assets/scss/mixins.scss';
@import 'src/assets/scss/colors.scss';

.editor {
  @include editorWidth(768px);
  :global {
    .ql-editor {
      min-height: 235px;
    }
  }
}
.viewWrapper {
  display: flex;
  background: $white;
  border: 1px solid $neutrals-1;
  border-radius: 10px;
  > div {
    max-width: 768px;
    width: 100%;
    padding: 16px;
    margin: 0 auto;
    min-height: calc(100vh - 300px);
  }
  a {
    text-decoration: underline;
  }
}
