@import 'src/assets/scss/colors.scss';

.wrapper {
  padding-bottom: 24px;
}
.regions {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
.pageTitleWrapper {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
}
.overviewWrapper {
  display: flex;
  gap: 20px;
  > div {
    &:nth-child(1) {
      flex: 1;
      min-width: 540px;
      max-width: 540px;
    }
    &:nth-child(2) {
      flex: 1;
    }
  }
}
.score {
  height: 190px;
  // width: 350px;
  margin: 0 auto;
  margin-top: 16px;
}
.scoreRight {
  flex: 1;
  margin-top: 8px;
  > div {
    &:nth-child(2) {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-top: 8px;
      > div {
        display: flex;
        justify-content: space-between;
        padding-right: 16px;
        &:nth-child(1) {
          display: block;
        }
      }
    }
  }
  > div {
    width: 100%;
  }
}
.cardMap {
  border: 0;
  > div > div > div {
    border: 1px solid $neutrals-0-5;
    border-radius: 6px;
    box-shadow: 0px 1px 2px 0px #1018280a;
  }
}
.map {
  margin: -16px;
  width: calc(100% + 32px);
}
.subTitle {
  display: flex;
  width: 100%;
  gap: 35px;
  > span {
    display: flex;
    flex-direction: column;
    gap: 4px;
    > span {
      &:nth-child(1) {
        font-weight: 700;
      }
    }
    &:nth-child(1) {
      max-width: 429px;
      min-width: 429px;
      > span {
        &:nth-child(1) {
          font-weight: 400;
        }
      }
    }
    &:nth-child(2) {
      max-width: 259px;
      min-width: 259px;
    }
  }
}
.warningIcon {
  margin-left: 8px;
  svg {
    path {
      fill: $error-3;
    }
  }
}
