.wrapper {
  flex: 1;
  height: 100%;
}

.locationsContentWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  margin-top: 8px;
  margin-bottom: 16px;
}
.description {
  font-size: 14px;
  line-height: 17px;
}
.locations {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 24px;
}
.titleButtons {
  margin-left: auto;
}
