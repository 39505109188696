@import 'src/assets/scss/colors.scss';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  background: #f9f9f9;
  border-radius: 4px;
  border: 1.5px solid $primary-button;
  padding: 8px 16px;
  height: 36px;
  cursor: pointer;
  > span {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    color: $primary-button;
  }
  > svg {
    min-width: 20px;
    width: 20px;
    height: 20px;
    path {
      stroke: $primary-button;
    }
  }
  &.isWhite {
    background: $white;
  }
}
.items {
  display: flex;
  flex-direction: column;
  gap: 6px;
  & + .items {
    > .parentLabel {
      margin-top: 10px;
    }
  }
  & + button {
    margin-top: 16px;
  }
}
.parentLabel {
  color: $black;
  display: flex;
  align-items: center;
  gap: 40px;
  & + .filterItem {
    margin-top: 0;
  }
  > span {
    &:nth-child(1) {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }
    &:nth-child(2) {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      color: $primary-button;
      cursor: pointer;
      display: inline-block;
      width: 80px;
      text-align: right;
    }
  }
}
.filterItem {
  margin-top: 6px;
}
.popoverOverlay {
  :global {
    .popover.show {
      border-radius: 8px;
      border: 0;
      box-shadow: 0px 4px 12px 0px rgba($black, 0.2);
      > .popover-inner {
        padding: 16px;
      }
    }
    .custom-control {
      .custom-control-label {
        > span {
          color: $black;
        }
      }
    }
  }
}
