.autosuggestInput {
  width: 100%;
  padding: 10px;
  height: 44px;
  border-radius: 4px !important;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.autosuggestContainer {
  position: relative;
  width: 100%;
}

.suggestionsList {
  list-style-type: none;
  margin: 0;
  padding: 0;
  border: 1px solid #ccc;
  max-height: 350px; /* Set max height for scrollable suggestions */
  overflow-y: auto;
  position: absolute;
  width: 100%;
  background-color: #fff;
  z-index: 1000;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Add shadow for floating effect */
  border-radius: 8px;
  margin-top: 4px; /* Space between the input and suggestions */
}

.suggestionItem {
  padding: 12px 16px;
  font-size: 14px;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth hover transition */

  &:hover {
    background-color: #f1f1f1; /* Light gray hover effect */
  }
}

.suggestionHighlighted {
  background-color: #ddd;
}
.cas {
  color: #0400ff;
}
