@import 'src/assets/scss/mixins.scss';
@import 'src/assets/scss/colors.scss';

.documentWrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  .thumbnail {
    width: 100%;
    height: 192px;
    cursor: pointer;
    img {
      border-radius: 4px;
      width: 100%;
      height: 100%;
    }
  }
  .name {
    display: flex;
    font-size: 16px;
    line-height: 150%;
    font-weight: 600;
    color: $black-1;
    margin-top: 10px;
  }
  .info {
    display: flex;
    align-items: center;
    gap: 16px;
    color: $neutrals;
    margin-top: 4px;
  }
  .deleteIcon {
    position: absolute;
    right: 16px;
    top: 14px;
  }
  .statusTag {
    position: absolute;
    left: 16px;
    top: 14px;
  }
}
.calendar {
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 12px;
  line-height: 150%;
  > span {
    &:nth-child(1) {
      display: flex;
      width: 20px;
      height: 20px;
      align-items: center;
      justify-content: center;
    }
  }
}
.placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $neutrals-7;
  height: 100%;
  width: 100%;
  border-radius: 4px;
  svg {
    width: 60px;
    height: 60px;
    path {
      fill: $neutrals-2;
    }
  }
}
.documentsWrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 28px;
}
.deleteIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: $white;
  cursor: pointer;
  svg {
    width: 13px;
    height: 13px;
  }
  &:hover {
    background: $error-0-5;
    svg {
      path {
        stroke: $error-3;
      }
    }
  }
  &:active {
    background: $error-3;
    svg {
      path {
        stroke: $white;
      }
    }
  }
}
.uploadWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 240px;
  width: 100%;
  height: calc(100vh - 300px);
  > div {
    max-width: 650px;
    width: 100%;
    > div {
      min-height: 200px;
      background: $white;
    }
  }
}
.documentsNotFoundText {
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: rgba($black, 0.5);
}
