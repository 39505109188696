@import 'src/assets/scss/colors.scss';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  :global {
    .page-item {
      .page-link,
      span {
        width: 32px;
        height: 32px;
        border: 0;
      }
      &.active {
        .page-link,
        span {
          background: $primary;
        }
      }
      &.disabled {
        cursor: not-allowed;
      }
    }
  }
  &.isLight {
    :global {
      .page-item {
        .page-link,
        span {
          background: transparent !important;
          color: $black;
          &:hover {
            color: $black;
          }
        }
        &.active {
          .page-link,
          span {
            color: $black;
            box-shadow: none !important;
            font-weight: bold;
          }
        }
      }
    }
  }
}
.sizeChanger {
  width: 150px;
}
.semiNext {
  padding-bottom: 8px !important;
}

.selectWrapper {
  > div {
    > div {
      background: transparent !important;
      * {
        color: black !important;
      }
    }
  }
}
