@import 'src/assets/scss/colors.scss';

.formFooter {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 24px;
}
.formInner {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.description {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: $black-2;
}
.modalWrapper {
  :global {
    .modal-body {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }
}
.form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.addressWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.addressLastCol {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  > div {
    &:nth-child(2) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;
    }
  }
}
