@import 'src/assets/scss/colors.scss';

.pageTitleWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.filePreview {
  margin-top: 16px;
}
