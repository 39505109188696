.dnode_form {
  text-align: left !important;
  background-color: rgb(205, 205, 205) !important;
}

.dnode_cond {
  text-align: left !important;
  background-color: rgb(205, 207, 152) !important;
}

.dnode_action {
  text-align: left !important;
  background-color: white !important;
}

.dnode i.fas {
  position: absolute;
  left: -13px;
  top: -10px;
  font-size: 20px;
  text-shadow: 0.5px 0.5px 0px black, 0.5px 0.5px 0px black,
    -0.5px -0.5px 0px black, -0.5px 0.5px 0px black, 0.5px -0.5px 0px black;
}

.dnode small {
  color: #777;
  display: block;
}

.dnode small.dnode_type {
  margin-top: -24px;
  margin-bottom: 10px;
}

.dnode_cond .fa-square {
  rotate: 45deg;
  color: gold;
}

.dnode_action .fa-square {
  color: white;
}

.dnode_form .fa-square {
  color: lightgray;
  transform: skewX(-16deg);
}

.dnode_unknown .fa-square {
  display: none;
}

.dnode hr {
  margin-bottom: 5px !important;
  margin-top: 5px !important;
  opacity: 1 !important;
}

.react-flow .react-flow__handle {
  transition: width 0.1s ease-out, height 0.1s ease-out;
}

.react-flow .react-flow__handle:hover {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: #4444f4;
}

.react-flow .react-flow__handle-top {
  top: -1px;
}

.react-flow .react-flow__handle-bottom {
  bottom: -1px;
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 340px;
  padding: 10px 10px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  min-height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  line-height: 1.25;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 280px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

/* .float-left {
    float: left;
    width:50%!important;
  }
  .float-right {
    float: right;
    width:50%!important;
  } */
/* .react-flow .react-flow__node {
    height: 40px;
    width: 150px;
    justify-content: center;
    align-items: center;
    display: flex;
    border-width: 2px;
    font-weight: 700;
  } */

/* .react-flow .react-flow__edge path,
  .react-flow__connectionline path {
  } */

.wrapper {
  flex-grow: 1;
  height: 100%;
}

.side-panel {
  position: absolute;
  right: -900px;
  top: 0;
  bottom: 0;
  width: 900px;
  background-color: #eee;
  transition: right 0.3s ease-in-out;
  border-left: 1px solid #777;
  padding: 20px;
  overflow-y: auto;
  overflow-x: hidden;
}

.side-panel-open {
  right: 0px;
}

.react-form-builder .react-form-builder-toolbar {
  margin-top: 0 !important;
}

.react-form-builder .react-form-builder-toolbar h4 {
  display: none !important;
}
.react-form-builder > div {
  display: flex;
  gap: 8px;
}

[aria-label='React Flow attribution'] {
  display: none;
}

.edge-tools {
  opacity: 0.1;
  transition: opacity 0.5s;
}

.edge-tools:hover {
  opacity: 1;
}

.form-floating label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.react-autosuggest__input {
  padding-left: 13px;
  padding-top: 12px;
  padding-bottom: 12px;
  width: 96%;
}

.react-flow__edge:hover {
}

.dropup .dropdown-menu[data-bs-popper] {
  bottom: 100%;
  margin-bottom: var(--bs-dropdown-spacer);
  margin-top: 0;
  top: auto;
}

.dropup .dropdown-toggle:after {
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
  border-right: 0.3em solid transparent;
  border-top: 0;
  content: '';
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
}

.dropup .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  left: 100%;
  margin-left: var(--bs-dropdown-spacer);
  margin-top: 0;
  right: auto;
  top: 0;
}

.dropend .dropdown-toggle:after {
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
  border-right: 0;
  border-top: 0.3em solid transparent;
  content: '';
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
}

.dropend .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropend .dropdown-toggle:after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  left: auto;
  margin-right: var(--bs-dropdown-spacer);
  margin-top: 0;
  right: 100%;
  top: 0;
}

.dropstart .dropdown-toggle:after {
  content: '';
  display: inline-block;
  display: none;
  margin-left: 0.255em;
  vertical-align: 0.255em;
}

.dropstart .dropdown-toggle:before {
  border-bottom: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-top: 0.3em solid transparent;
  content: '';
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
}

.dropstart .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropstart .dropdown-toggle:before {
  vertical-align: 0;
}

/*# sourceMappingURL=app.css.map*/

.ReactTags__tags {
  position: relative;
}

.ReactTags__clearAll {
  border-radius: 4px;
  cursor: pointer;
  padding: 4px 10px;
  margin: 10px;
  background: #bababa;
  color: #fff;
  border: none;
}

.ReactTags__tagInput {
  border-radius: 2px;
  display: inline-block;

  input.ReactTags__tagInputField {
    height: 32px;
    margin: 0;
    padding: 5px;
    font-size: 14px;
    border: 1px solid #eee;
    min-width: 280px;

    &:focus {
      height: 32px;
      margin: 0;
      padding: 5px;
      font-size: 14px;
      border: 1px solid #eee;
      min-width: 280px;
    }
  }
}

.ReactTags__editInput {
  border-radius: 1px;
}

.ReactTags__editTagInput {
  display: inline-flex;
}

.ReactTags__remove {
  display: flex;
}

.ReactTags__selected {
  display: flex;
  justify-content: flex-start;

  span.ReactTags__tag {
    border: 1px solid #ddd;
    background: #bcbcbc;
    color: #fff;
    font-size: 14px;
    padding: 5px;
    margin-right: 10px;
    border-radius: 2px;
    display: flex;
    align-items: center;
  }
}

.ReactTags__suggestions {
  position: absolute;
  z-index: 10;

  ul {
    list-style-type: none;
    box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
    background: white;
    width: 200px;
    padding: 5px 0;
  }

  li {
    padding: 5px 10px;
    margin: 0;
    text-align: left;

    mark {
      text-decoration: underline;
      background: none;
    }

    &.ReactTags__activeSuggestion {
      background: #4dabf7;
      color: #fff;
      cursor: pointer;

      span mark {
        color: #fff;
      }
    }
  }
}

.ReactTags__remove {
  border: none;
  cursor: pointer;
  background: none;
  color: #fff;
  padding: 5px;
}

.ReactTags__error {
  color: #e03131;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    padding: 0 5px;
  }
}

span.tag-wrapper.ReactTags__tag:before {
  content: '⚑';
  position: absolute;
  margin-left: -22px;
  margin-top: -14px;
  background-color: inherit;
  width: 16px;
  text-align: center;
  line-height: 18px;
  border-radius: 6px 0 0 6px;
}

.tab-tag {
  background-color: #6c757d;
  color: white;
  padding: 2px;
  width: 16px;
  text-align: center;
  line-height: 12px;
  border-radius: 50%;
  width: 16px;
  display: inline-block;
  margin-left: 3px;
}

.tag-bg-warning {
  background-color: rgb(220, 53, 69) !important;
}

span.tag-wrapper.ReactTags__tag {
  white-space: nowrap;
}

.tag-bg-attention-required {
  background-color: #17a2b8 !important;
}

.tag-bg-ready {
  background-color: #6c757d !important;
}

.tag-bg-overdue {
  background-color: #007bff !important;
}

.form-floating {
  position: relative;
}
.form-floating label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-2.7rem) translateX(-0.95rem);
}

.form-floating > label {
  border: 1px solid transparent;
  height: 100%;
  left: 0;
  overflow: hidden;
  padding: 1rem 0.85rem;
  pointer-events: none;
  position: absolute;
  text-align: start;
  text-overflow: ellipsis;
  top: 0;
  white-space: nowrap;
  z-index: 2;
}
.modal-body.modal-body-scroll {
  max-height: calc(100vh - 150px);
  overflow: auto;
}
