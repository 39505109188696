.btn {
  width: 48px;
  height: 48px;
  svg {
    min-width: 24px;
  }
}
.wrapper {
  display: flex;
  &.center {
    justify-content: center;
  }
}
