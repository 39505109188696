.wrapper {
  ul {
    padding: 0;
    margin: 0;
    gap: 8px;
    list-style: none;

    li {
      gap: 8px;
      font-size: 12px;
      line-height: 20px;
      color: #262726;
      font-weight: 400;
      border-radius: 14px;
      border: 1px solid #e5e5e5;
      background: white;
      margin-bottom: 10px;

      a {
        color: #262726;
      }

      span {
        font-weight: 600;
      }
    }
  }

  hr {
    margin-top: 4px !important;
    margin-bottom: 14px !important;
  }

  table {
    margin-bottom: 0px;

    td {
      vertical-align: top;
    }

    button {
      float: right;
    }
  }
}
