@import 'src/assets/scss/colors.scss';
@import 'src/assets/scss/mixins.scss';

.hide {
  display: none !important;
}
.label {
  font-size: 14px;
  line-height: 20px;
  color: $black-1;
  font-weight: 500;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  gap: 4px;
}
.menuSelectAllWrapper {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  margin: 8px 16px;
}
.error {
  margin-top: 8px;
  // color: #5a0000 !important;
  font-size: 80%;
  line-height: 19px;
  font-weight: 400;
  display: none;
  > svg {
    position: relative;
    top: -2px;
    margin-right: 6px;
  }
}
.wrapper {
  &.isError {
    .error {
      display: block;
    }
  }
}
.selectMultipleWrapper {
  display: flex;
  flex-direction: column;
  > select {
    min-width: 100% !important;
    max-width: 100% !important;
    width: 100% !important;
    overflow-y: auto !important;
  }
}
.selectMultipleInputWrapper {
  &.radius_md {
    border-radius: 4px !important;
  }
  &.variant_primary {
    &:focus {
      border-color: $primary;
      box-shadow: 0px 0px 0px 2px #09624233;
    }
  }
  &.fontSize_sm {
    font-size: 14px;
    line-height: 20px;
  }
}
.checkbox {
  :global {
    .custom-control-label {
      > span {
        color: $black;
      }
    }
  }
}
.groupHeadingCheckbox {
  :global {
    .custom-control-label {
      > span {
        display: flex;
        align-items: center;
        gap: 4px;
        font-weight: 600;
        position: relative;
        top: 0;
        > span {
          &:nth-child(2) {
            font-weight: 400;
          }
        }
      }
    }
  }
}

.selectLocationGroupWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  .list {
    display: flex;
    align-items: center;
    gap: 16px;
    flex-wrap: wrap;
  }
}
.menuWithCheckbox {
  :global {
    .custom-checkbox .custom-control-input ~ .custom-control-label {
      height: auto;
    }
  }
}
.multipleValuesLabel {
  @include more-text(1);
  max-width: calc(100% - 10px);
}
