@import 'src/assets/scss/mixins.scss';
@import 'src/assets/scss/colors.scss';
@import 'src/assets/scss/variables.scss';

.childContent {
  padding: 8px 24px;
  min-height: calc(100vh - #{$topbarHeight});
  background: #f9f9f9;
  display: flex;
  flex-direction: column;
  position: relative;
}
.backdrop {
  z-index: 101 !important;
}
.wrapper {
  &.whiteBackground {
    .childContent {
      background: $white !important;
    }
  }
}
