@import 'src/assets/scss/colors.scss';
@import 'src/assets/scss/mixins.scss';

:global {
  .react-datepicker__tab-loop {
    .react-datepicker__triangle {
      display: none !important;
    }
    .react-datepicker-popper {
      z-index: 20;
    }
    .react-datepicker-popper[data-placement^='bottom'] {
      margin-top: 0;
    }
  }
}

.wrapper {
  position: relative;
  &.radius_md {
    input {
      border-radius: 4px !important;
    }
  }
  :global {
    .react-datepicker-wrapper {
      width: 100%;
      height: 44px;
      .react-datepicker__input-container {
        width: 100%;
        input.form-control {
          border-color: rgba($black, 0.2);
          box-shadow: none !important;
          color: $black !important;
          height: 44px;
          &:focus {
            border-color: rgba($black, 0.8);
          }
          @include inputPlaceholderColor();
          &.is-invalid {
            border-color: rgba($error-5, 0.8) !important;
          }
        }
      }
    }
  }
}
.label {
  @include inputLabel();
}
.error {
  @include inputError();
}
