.wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  > svg {
    min-width: 20px;
    max-width: 20px;
    min-height: 20px;
    max-height: 20px;
  }
  &.fontSize_sm {
    font-size: 12px;
    line-height: 150%;
  }
}
