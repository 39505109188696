.wrapper {
  height: 48px;
  width: 148px;
  display: flex;
  > svg {
    height: 48px;
    width: 148px;
    position: relative;
  }
  &.isMini {
    width: 40px;
    height: 40px;
    > svg {
      width: 40px;
      height: 40px;
    }
  }
  &.variant_authentication {
    width: 275px;
    height: 88px;
    > svg {
      width: 275px;
      height: 88px;
      left: 0;
    }
  }
}
