.locationColInner {
  display: flex;
  flex-direction: column;
  gap: 4px;
  > span {
    color: black;
    &:nth-child(1) {
      font-weight: 500;
    }
    &:nth-child(2) {
      font-size: 12px;
      line-height: 14px;
    }
  }
}
