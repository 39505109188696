@import 'src/assets/scss/colors.scss';

.formFooter {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 16px;
  padding: 16px 24px;
}
.formInner {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 24px;
  min-height: 450px;
  max-height: 450px;
  overflow-x: hidden;
  overflow-y: auto;
  // max-height: calc(100vh - 240px);
}
.description {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: $black-1;
}
.modalWrapper {
  :global {
    .modal-body {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
    .modal-dialog {
      max-width: 700px;
      .modal-body {
        padding: 0;
      }
    }
  }
  &.isSingle {
    .formInner {
      min-height: 300px;
      max-height: 300px;
    }
  }
}
.form {
  display: flex;
  flex-direction: column;
}
.uploadResult {
  display: flex;
  flex-direction: column;
  gap: 16px;
  > span {
    font-size: 14px;
    line-height: 20px;
  }
}
