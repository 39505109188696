@import 'src/assets/scss/colors.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: $black-1;
  &.bold {
    .label {
      font-weight: 700;
      margin-bottom: 0;
    }
    .label,
    .value {
      line-height: 17px;
    }
  }
}
.label,
.value {
  font-size: 14px;
  line-height: 20px;
}
.label {
  margin-bottom: 4px;
  font-weight: 500;
}
.value {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
