@import 'src/assets/scss/colors.scss';

.pageTitleWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.infoWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.info {
  display: flex;
  align-items: center;
  gap: 12px;
  flex: 1;
  > * {
    flex: 1;
    max-width: 200px;
  }
}
.organization {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: $primary-button;
}
.pageTitle {
  > div {
    gap: 28px !important;
  }
}
.tagCategoryRemove {
  display: flex;
  align-items: center;
  gap: 12px;
  flex: 1;
}
.addNewBtn {
  margin-left: auto !important;
}
.richTextEditor {
  margin-top: 8px;
}
