@import 'src/assets/scss/colors.scss';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background: $gray-3;
  border-radius: 6px;
  > div {
    flex: 1;
    background: $white;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    min-height: 500px;
    border-radius: 6px;
    > span {
      color: $black;
      &:nth-child(1) {
        font-size: 24px;
        line-height: 30px;
      }
      &:nth-child(2) {
        font-size: 32px;
        line-height: 38px;
        font-weight: 600;
      }
    }
  }
}
