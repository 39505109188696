@import 'src/assets/scss/colors.scss';

.wrapper {
  display: flex;
  flex-direction: column;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $neutrals-0-5;
  padding: 24px 16px;
  border-radius: 8px;
}
.content {
  display: flex;
  flex-direction: column;
  > div {
    &:nth-child(1) {
      min-height: 30px;
    }
    &:nth-child(2) {
      display: flex;
      flex-direction: column;
      gap: 30px;
    }
  }
}
