@import 'src/assets/scss/colors.scss';

@mixin beauty-scroll() {
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: rgba($black, 0.5) transparent;
}
@mixin hide-scroll() {
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none; /* Chrome, Edge, Safari */
  }
}
@mixin more-text($line: 1) {
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
@mixin inputPlaceholderColor() {
  &::placeholder {
    color: rgba($black, 0.4) !important;
  }
}
@mixin inputLabel() {
  font-size: 14px;
  line-height: 20px;
  color: $black-1;
  font-weight: 500;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  gap: 4px;
}
@mixin inputError() {
  margin-top: 8px;
  font-size: 13px;
  line-height: 19px;
  font-weight: 400;
  > svg {
    position: relative;
    top: -2px;
    margin-right: 6px;
  }
  &:global(.invalid-feedback) {
    color: rgba($error-5, 0.8);
  }
}
@mixin editorWidth($width) {
  :global {
    .ql-toolbar {
      .ql-toolbar.ql-snow {
        margin: 0 auto;
        max-width: $width;
      }
    }
    .ql-container {
      .ql-editor {
        max-width: $width;
        margin: 0 auto;
      }
    }
  }
}
