@import 'src/assets/scss/colors.scss';
@import 'src/assets/scss/mixins.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-size: 14px;
  line-height: 20px;
  color: $black;
  border: 1px solid $gray-3;
  border-radius: 6px;
  padding: 16px;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  > div {
    display: flex;
    gap: 8px;
    align-items: center;
  }
  > span {
    @include more-text(1);
  }
}
.title {
  font-weight: 600;
}
.content {
  display: flex;
  flex-direction: column;
  gap: 12px;
  > div {
    display: flex;
    flex-direction: column;
    gap: 2px;
    > span {
      &:nth-child(1) {
        font-weight: 600;
      }
    }
  }
}
.descriptionField {
  @include more-text(2);
}
