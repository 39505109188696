@import 'src/assets/scss/colors.scss';

.modalWrapper {
  :global {
    .modal-dialog {
      max-width: 650px;
    }
    .modal-content {
      border-radius: 4px !important;
      .modal-body {
        padding: 0;
        > div {
          > div {
            min-height: 200px;
            border-radius: 4px;
          }
        }
      }
    }
  }
}
