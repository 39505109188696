@import 'src/assets/scss/colors.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  padding: 16px;
  border: 1px solid #d8d8d8;
  border-radius: 6px;
  box-shadow: 0px 2px 4px 0px #0000000a;
}
.header {
  padding-bottom: 16px;
  border-bottom: 1px solid #e1e7ec;
  color: $black-1;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}
.content {
  padding-top: 8px;
  ul {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    width: 100%;
    gap: 16px;
    > li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      font-size: 14px;
      line-height: 20px;
      gap: 20px;
      > div {
        display: flex;
        align-items: center;
        gap: 8px;
        &:nth-child(1) {
          min-width: 150px;
          max-width: 150px;
        }
        &:nth-child(2) {
          min-width: 115px;
          max-width: 115px;
          font-size: 12px;
          gap: 4px;
          > div {
            top: -2px;
          }
          > label {
            margin: 0;
            cursor: pointer;
          }
        }
        &:nth-child(3) {
          flex: 1;
          justify-content: flex-end;
          > div {
            max-width: 85px;
          }
          input.timeInputIsActive {
            border-color: rgba(0, 0, 0, 0.8) !important;
          }
          input {
            padding-right: 6px;
          }
        }
      }
      &.isTimeDisable {
        opacity: 0.7;
      }
    }
  }
}
.open24HoursText {
  display: inline-flex;
  gap: 4px;
  height: 44px;
  align-items: center;
}
