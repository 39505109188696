@import 'src/assets/scss/colors.scss';

:global {
  .g-sidenav-hidden {
    .page-footer-wrapper {
      left: 90px;
    }
  }
  .g-sidenav-pinned.g-sidenav-show {
    .page-footer-wrapper {
      left: 260px;
    }
  }
}

.wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: $white;
  padding: 16px 24px;
  > div {
    display: flex;
    gap: 16px;
    align-items: center;
  }
  &.fixed {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 100;
  }
  &:not(.fixed) {
    width: 100%;
  }
  :global {
    .page-footer-selected-text {
      font-size: 14px;
      font-weight: 600;
      color: $black;
      padding: 8px 12px;
    }
  }
}
