@import 'src/assets/scss/colors.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 8px;
  .title {
    font-weight: 600;
    color: $black-1;
  }
}
.selectLocations {
  display: flex;
  align-items: flex-end;
  gap: 16px;
  > div {
    flex: 1;
  }
}
.addedLocations {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}
.noLocationMessage {
  font-size: 14px;
  line-height: 20px;
}
