@import 'src/assets/scss/colors.scss';
@import 'src/assets/scss/mixins.scss';

$radius: 10px;

.wrapper {
  :global {
    .ql-toolbar {
      background: $neutrals-0-5;
      border-radius: $radius $radius 0 0;
      border-color: $neutrals-1;
      .ql-toolbar.ql-snow {
        border-radius: $radius $radius 0 0;
        padding: 0 !important;
        border: 0 !important;
        .ql-formats {
          margin: 0;
          padding-left: 12px;
          padding-right: 12px;
          border-right: 1px solid $neutrals-2;
          &:first-child {
            padding-left: 6px;
          }
          &:last-child {
            border-right: 0;
          }
        }
      }
    }
    .quill {
      .ql-container.ql-snow {
        border-color: $neutrals-1;
        border-radius: 0 0 $radius $radius;
        background: transparent !important;
        .ql-editor {
          border: 0;
          border-radius: 0 0 $radius $radius;
          color: $black-1;
          background: transparent !important;
          box-shadow: none !important;
        }
      }
    }
  }
}
