.pageTitle,
.formInner {
  max-width: 646px;
}
.formFooter {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: auto;
  margin-left: -24px;
  margin-right: -24px;
  margin-bottom: -8px;
  background: white;
  padding: 16px 24px;
}
.wrapper {
  flex: 1;
}
.form {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.formInner {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
