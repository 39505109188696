.pageTitle {
  max-width: 715px;
}
.formFooter {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  background: white;
  box-shadow: 0px -4px 12px 0px #0000000d;
  padding: 16px 22px;
  margin: auto -24px -16px -24px;
}
.wrapper {
  flex: 1;
  padding-bottom: 8px;
}
.form {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.formInner {
  display: flex;
  gap: 24px;
  > div {
    &:nth-child(1) {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
    &:nth-child(2) {
      flex: 1;
      max-width: 573px;
      min-width: 573px;
      height: 500px;
    }
  }
}
.descriptionInput {
  min-height: 90px !important;
}
.overviewInput {
  min-height: 227px !important;
}
.locationWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.map {
  width: 100%;
  height: 300px;
}
