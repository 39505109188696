@import 'src/assets/scss/colors.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  background: $white;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  > span {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: black;
  }
  > div {
    display: flex;
    align-items: center;
    gap: 16px;
    > div {
      &:nth-child(1) {
        width: 232px;
      }
      &:nth-child(2) {
      }
      &:nth-child(3) {
      }
    }
  }
}
.tableWrapper {
  display: flex;
  flex-direction: column;
  border: 1px solid $gray-2;
  border-radius: 6px;
  :global {
    .table-responsive {
      table {
        thead {
          tr {
            th {
              border-top: 0;
              &:first-child {
                border-radius: 6px 0 0 0;
              }
              &:last-child {
                border-radius: 0 6px 0 0;
              }
            }
          }
        }
      }
    }
  }
}
