.g-sidenav-pinned .sidenav {
  max-width: 260px !important;
}

@media (min-width: 1200px) {
  .g-sidenav-pinned .sidenav.fixed-left + .main-content {
    margin-left: 260px;
  }
}
.g-sidenav-hidden {
  .navbar-vertical.navbar-expand-xs.fixed-left {
    & + .main-content {
      margin-left: 80px !important;
    }
  }
}
