@import 'src/assets/scss/colors.scss';

.wrapper {
  font-weight: 400;
  // font-size: 16px;
  // line-height: 24px;
  border: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  min-height: 44px;
  background: linear-gradient(180deg, #2d3f58 0%, #1d2d44 100%);
  &:global(.btn) {
    padding-left: 16px;
    padding-right: 16px;
  }
  &:disabled {
    background: linear-gradient(180deg, #d1d1d1 0%, #b9b9b9 100%) !important;
  }
  &.variant_primary {
    color: white !important;
    background: $primary-button !important;
    &.isLink {
      color: $primary-button !important;
    }
    &.isGhost {
      color: $primary-button !important;
      background: transparent !important;
      border: 1px solid $primary-button !important;
    }
  }
  &.variant_primary_fade {
    color: $primary-button !important;
    background: transparent !important;
    box-shadow: none !important;
    &:hover {
      background: rgba($primary-button, 0.1) !important;
    }
  }
  &.variant_red {
    color: white !important;
    background: $error-4 !important;
    &.isLink {
      color: $error-4 !important;
    }
  }
  &.variant_default {
    color: #262c32 !important;
    background: transparent !important;
    border: 2px solid #6f777f24 !important;
    box-shadow: none !important;
    font-weight: 500;
    &.isLink {
      color: #262c32 !important;
    }
  }
  &.variant_gray {
    color: $black !important;
    background: #eaeaea !important;
    border: 2px solid #eaeaea !important;
    box-shadow: none !important;
    font-weight: 500;
    &.isLink {
      color: #eaeaea !important;
    }
  }
  &.isVariant {
    &:disabled {
      opacity: 0.9;
      background: #dbdddd !important;
      box-shadow: none !important;
      color: #a1a1aa !important;
      svg {
        path {
          fill: black;
        }
      }
    }
  }
  &.fontSize_sm {
    font-size: 14px;
    line-height: 20px;
  }
  &.fontSize_xs {
    font-size: 12px;
    line-height: 18px;
  }
  &.isLink {
    border: 0 !important;
    background: transparent !important;
    box-shadow: none !important;
  }
  &.size_medium {
    min-height: 36px;
    height: 36px;
  }
  &.weight_md {
    font-weight: 500 !important;
  }
  &.weight_lg {
    font-weight: 600 !important;
  }
  &.fullWidth {
    width: 100% !important;
  }
  &.shadow_no {
    box-shadow: none !important;
  }
  &.rounded_lg {
    border-radius: 6px;
  }
  &:global(.btn) {
    display: flex;
  }
}
.rightIcon {
  display: flex;
  margin-left: 4px;
}
.loading {
  display: inline-flex;
  :global {
    .spinner-border {
      width: 20px;
      height: 20px;
      border-width: 2px;
    }
  }
}
