.previewBox {
  width: 400px;
  height: 180px;
  padding: 20px;
  margin: 10px 0;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: auto;
  button {
    margin-left: 10px !important;
  }
}
