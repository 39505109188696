@import 'src/assets/scss/colors.scss';

.formFooter {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  padding: 16px 24px;
}
.content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 24px;
  min-height: 450px;
  max-height: 450px;
  overflow-x: hidden;
  overflow-y: auto;
}
.description {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: $black-1;
}
.modalWrapper {
  :global {
    .modal-body {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
    .modal-dialog {
      .modal-body {
        padding: 0;
      }
    }
  }
}

.actions {
  display: flex;
  gap: 24px;
  justify-content: flex-end;
  align-items: center;
}
.quickAccessTable {
  table {
    tbody {
      tr {
        &:hover {
          td {
            background: transparent !important;
          }
        }
        td {
          border-color: #d1d9e2 !important;
        }
      }
    }
  }
}
