.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  > div {
    display: flex;
    flex-direction: column;
    gap: 24px;
    > span {
      text-align: center;
      font-size: 28px;
      line-height: 33px;
    }
  }
}
