@import 'src/assets/scss/colors.scss';

.wrapper {
  flex: 1;
  padding-bottom: 8px;
  h2 {
    margin: 0;
    color: $black-1;
    font-size: 18px;
    line-height: 22px;
  }
}
