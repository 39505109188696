@import 'src/assets/scss/colors.scss';

.wrapper {
  &.isPage {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: #fffc;
    z-index: 10;
  }
  &.isBottom {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.loadingCircleWrapper {
  position: relative;
  width: 54px;
  height: 54px;
  svg {
    width: 100%;
    height: 100%;
    transform: rotate(-90deg);
  }
  circle {
    fill: none;
    stroke-width: 2;
    // stroke-linecap: round;
  }
  .loadingCirclePercent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 12px;
    line-height: 20px;
    font-weight: 600;
    color: $primary-button;
  }
  .loadingCircleBg {
    stroke: #ddd; /* Background Circle */
  }
  .loadingCircleProgress {
    stroke: $primary-button;
    stroke-dasharray: 125.6;
    stroke-dashoffset: 125.6; /* Start from empty */
    animation: progress 3s linear forwards;
  }
}

@keyframes progress {
  0% {
    stroke-dashoffset: 125.6;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
