@import 'src/assets/scss/colors.scss';

:global {
  .tooltip {
    .arrow {
      display: none !important;
    }
    .tooltip-inner {
      background: #fafafa;
      border: 1px solid #d7d7d7;
      border-radius: 8px;
      color: black;
      padding: 12px;
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
      text-align: left;
      max-width: 500px;
    }
    &.tooltip-overlay-light {
      &.show {
        opacity: 1;
      }
      .tooltip-inner {
        background: $white;
        box-shadow: 0px 0px 8px 0px #0000001a;
        color: #0a0a0a;
        font-size: 14px;
        line-height: 17px;
      }
    }
    &.tooltip-overlay-dark {
      .tooltip-inner {
        background: $black;
        box-shadow: 0px 2px 8px 0px rgba($black, 0.2);
        color: $white;
        font-size: 14px;
        line-height: 17px;
      }
    }
    &.tooltip-has-arrow {
      .arrow {
        display: block !important;
        width: 20px;
        top: -6px !important;
        height: 15px;
        &:before {
          border-width: 0 10px 10px !important;
          width: 20px;
          height: 15px;
        }
      }
      &.tooltip-overlay-right {
        padding-left: 20px;
        .arrow {
          top: -6px !important;
          bottom: 0px !important;
          margin-top: auto;
          margin-bottom: auto;
          left: 16px;
          &:before {
            border-width: 10px 10px 10px 0 !important;
            right: -1px !important;
            border-right-color: white;
          }
          &:after {
            border-color: transparent;
            border-right-color: #d7d7d7;
            position: absolute;
            content: '';
            border-style: solid;
            width: 22px;
            height: 17px;
            border-width: 11px 11px 11px 0 !important;
            right: -1px !important;
            z-index: -1;
            top: -1px;
          }
        }
        &.tooltip-overlay-real-placement-right-top {
          .arrow {
            margin-top: 0;
            margin-bottom: 0;
            bottom: 0 !important;
            top: 28px !important;
          }
        }
      }
    }
    &.tooltip-overlay-font-size_sm {
      .tooltip-inner {
        font-size: 12px;
        line-height: 150%;
        font-weight: 400;
      }
    }
  }
}
