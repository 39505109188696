@import 'src/assets/scss/colors.scss';

.wrapper {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: $black;
  display: flex;
  flex-direction: column;
  gap: 16px;
  .subTitle {
    font-size: 14px;
    color: $black-2;
    line-height: 17px;
    font-weight: 400;
    &.size_large {
      font-size: 16px;
      line-height: 20px;
    }
  }
  .title {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  &.gap_sm {
    gap: 8px;
  }
}
