.wrapper {
  position: relative;
  svg {
    width: 100%;
  }
  &.hasIssues {
    .info {
      bottom: 26px;
    }
  }
}
.info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2px;
  color: black;
  justify-content: center;
  align-items: center;
}
.title {
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
}
.score {
  font-size: 32px;
  line-height: 38px;
  font-weight: 800;
}
.issues {
  display: flex;
  justify-content: center;
  margin-top: 12px;
  > div {
    display: flex;
    > span {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      color: black;
      padding: 4px 12px;
      border-radius: 24px;
      gap: 4px;
      svg {
        width: 16px;
        min-width: 16px;
        height: 16px;
      }
    }
  }
}
.scoreColor {
  display: flex;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.scoreChart {
  display: flex;
  flex-direction: column;
  gap: 4px;
  > span {
    font-size: 14px;
    font-weight: 600;
    color: black;
  }
  .scores {
    height: 40px;
    background: #eeeeee;
    padding: 4px;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    > span {
      display: flex;
      width: 8px;
      border-radius: 1px;
    }
  }
}
.scoreReport {
  display: flex;
  align-items: flex-end;
  gap: 16px;
  justify-content: space-between;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  background: white;
  padding: 12px;
  > div {
    display: flex;
    flex-direction: column;
    gap: 2px;
    flex: 1;
    > span {
      color: black;
      font-size: 14px;
      line-height: 16px;
      &:nth-child(1) {
        font-weight: 600;
      }
    }
  }
  > svg {
    width: 16px;
    min-width: 16px;
    height: 16px;
    cursor: pointer;
  }
}
