@import 'src/assets/scss/colors.scss';

.formFooter {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 24px;
}
.formInner {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.description {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: $black-1;
}
.modalWrapper {
  :global {
    .modal-body {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }
}
.form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
