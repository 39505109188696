.wrapper {
  display: flex;
  justify-content: center;
  > div {
    display: flex;
    width: 100%;
    max-width: 656px;
    align-items: center;
    padding: 16px;
    background: white;
    border: 1px solid #d8d8d8;
    border-radius: 6px;
    gap: 32px;
    > span {
      font-size: 14px;
      &:nth-child(1) {
        line-height: 17px;
        color: #1f1f1f;
      }
      &:nth-child(2) {
        cursor: pointer;
        color: black;
        font-weight: 500;
        line-height: 20px;
        display: inline-block;
        min-width: 115px;
        max-width: 115px;
        margin-right: 12px;
      }
    }
  }
}
