@import 'src/assets/scss/colors.scss';

.doughnutChartWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  canvas {
    width: 100% !important;
    height: 100% !important;
  }
}
.doughnutLegends {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 12px;
  > span {
    display: inline-flex;
    height: 16px;
    align-items: center;
    gap: 6px;
    > span {
      &:nth-child(1) {
        height: 4px;
        width: 12px;
        border-radius: 12px;
        background: $primary;
      }
      &:nth-child(2) {
        color: #b9b9b9;
        font-size: 12px;
        line-height: 12px;
        font-weight: 400;
      }
      &:nth-child(3) {
        font-size: 12px;
        line-height: 12px;
        font-weight: 700;
        color: black;
      }
    }
  }
}
