.pageTitle {
  max-width: 715px;
}
.formFooter {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  background: white;
  box-shadow: 0px -4px 12px 0px #0000000d;
  padding: 16px 22px;
  margin: auto -24px -16px -24px;
}
.wrapper {
  flex: 1;
  padding-bottom: 8px;
}
.form {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.formInner {
}
.descriptionInput {
}
.overviewInput {
}
.locationWrapper {
}
.map {
  width: 100%;
  height: 300px;
}
.infoBox {
  background-color: #eee;
  font-style: italic;
  margin-left: 20px;
  display: block;
  padding: 10px;
  border-radius: 4px;
  width: 500px;
}
