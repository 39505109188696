.workflows {
  flex: 1;
  height: 100%;
}
.pageTitleWrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}
.wrapper {
  flex: 1;
  height: 100%;
}
