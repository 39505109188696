@import 'src/assets/scss/colors.scss';

.accessItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 18px 12px;
  background: $white;
  border: 1px solid $neutrals-2;
  color: $black;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  &:hover {
    background: $neutrals-0-5;
  }
  &.active,
  &:visited,
  &:active {
    background: $primary;
    border-color: $neutrals-9-5;
    color: $white;
    svg {
      path {
        stroke: $white !important;
      }
    }
  }
  &.type_warning {
    border-color: $warning-3;
    &:hover {
      background: $warning-0-5;
    }
    &.active,
    &:visited,
    &:active {
      background: $warning-3;
    }
    svg {
      path {
        stroke: $warning-3;
      }
    }
  }
  &.type_error {
    border-color: $error-3;
    &:hover {
      background: $error-0-5;
    }
    &.active,
    &:visited,
    &:active {
      background: $error-3;
    }
    svg {
      path {
        stroke: $error-3;
      }
    }
  }
}
.tabList {
  display: flex;
  flex-direction: column;
  gap: 18px;
}
.tabListInner {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 18px;
}
.tabLabel {
  display: flex;
  align-items: center;
  gap: 8px;
}
.title {
  display: flex;
  align-items: center;
  flex: 1;
  gap: 18px;
  margin-bottom: 22px;
  .text {
    font-weight: 700;
    font-size: 22px;
    line-height: 33px;
    color: $black-1;
  }
}
.sectionLabel {
  font-size: 14px;
  font-weight: 600;
  color: $black;
  margin: 0;
}
.tabExtraInner {
  display: flex;
  min-width: 120px;
}
.wrapper {
  .tabs {
    > div {
      &:nth-child(2) {
        > div {
          padding: 24px 0;
          gap: 24px;
        }
      }
    }
  }
}
