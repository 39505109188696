.faCodeWrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
  > div {
    > span {
      color: black;
    }
  }
  p {
    margin: 0;
  }
  > p {
    color: black;
    font-weight: 500;
  }
}
.subTitle1 {
  font-size: 16px;
  font-weight: 400;
  color: black;
}
.faCardBody {
  margin-top: 0;
  img {
    width: 213px;
    height: 213px;
    margin: auto;
  }
}
.faCodeInput {
  display: flex;
  justify-content: center;
  gap: 4px;
  input {
    max-width: 200px;
  }
  > div {
    width: auto;
  }
}
