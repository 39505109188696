@import 'src/assets/scss/colors.scss';

.modalFooter {
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}
.description {
  font-size: 14px;
  line-height: 20px;
  color: $black-2;
  margin-bottom: 16px;
}
.modalWrapper {
  max-width: 456px;
}
