.wrapper {
  :global {
    .dynamic-option-list {
      .dynamic-options-actions-buttons {
        display: flex;
        align-items: center;
        gap: 8px;
        button {
          margin: 0 !important;
        }
      }
    }
  }
}
