$primary-button: #06402b;
$primary: #082a15;
$primary-0-5: #e6eae8;
$primary-1: #9caaa1;
$primary-2: #84958a;
$primary-3: #6b7f73;
$primary-4: #526a5b;
$primary-5: #395544;
$primary-6: #213f2c;
$primary-8: #072613;
$primary-9: #062211;
$primary-9-5: #061d0f;

$secondary: #d8ab12;
$secondary-0-5: #fbf7e7;
$secondary-1: #f7eed0;
$secondary-2: #efdda0;
$secondary-3: #e8cd71;
$secondary-4: #e4c459;
$secondary-5: #e0bc41;
$secondary-6: #dcb32a;
$secondary-8: #c29a10;
$secondary-9: #ad890e;
$secondary-9-5: #97780d;

$neutrals: #52565b;
$neutrals-0-5: #eeeeef;
$neutrals-1: #dcddde;
$neutrals-2: #cbccce;
$neutrals-3: #a9abad;
$neutrals-4: #86898c;
$neutrals-5: #75787c;
$neutrals-6: #63676b;
$neutrals-7: #868a8d;
$neutrals-8: #424549;
$neutrals-9: #313437;
$neutrals-9-5: #212224;

$black: #000000;
$black-1: #09090b;
$black-2: #181818;
$black-3: #545454;

$white: #ffffff;

$info-0-5: #e8f1fc;
$info-1: #bad4f7;
$info-2: #468de9;
$info-3: #1870e3;

$feature-0-5: #f1ecfa;
$feature-1: #c7b2e9;
$feature-2: #9065d3;
$feature-3: #743fc8;

$error-0-5: #fceaed;
$error-1: #f4abb7;
$error-2: #eb6b82;
$error-3: #e32c4c;
$error-4: #b50000;
$error-5: #f43f5e;

$warning-0-5: #fef1e8;
$warning-1: #fdc7a2;
$warning-2: #fb9d5c;
$warning-3: #f97316;

$success-0-5: #e9f6ea;
$success-1: #a7dbac;
$success-2: #64c16d;
$success-3: #22a62f;

$gray-1: #99a096;
$gray-2: #d1d9e2;
$gray-3: #e4e4e4;

$bone-1: #d8d8c6;
