@import 'src/assets/scss/mixins.scss';
@import 'src/assets/scss/colors.scss';

.wrapper {
  position: absolute;
  right: 20px;
  bottom: 20px;
  width: 100%;
  max-width: 646px;
  .resultItem {
    background: $white;
  }
}
