.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 160px 24px 80px 24px;
  > div {
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    align-items: center;
    button {
      margin-top: 8px;
    }
    > span {
      font-size: 20px;
      line-height: 24px;
    }
  }
}
