@import 'src/assets/scss/colors.scss';

.modalFooter {
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}
.description {
  font-size: 14px;
  line-height: 17px;
  color: $black-2;
  margin-bottom: 16px;
}
.alert {
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 4px;
  background: #ffd4d4;
  color: $black-2;
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
  gap: 8px;
  svg {
    width: 24px;
    height: 24px;
  }
}
