.rna-wrapper {
  .alert {
    display: flex !important;
    // max-width: 600px;
    // width: calc(100% - 30px);
    padding-right: 80px;
    box-shadow: 0 3px 10px rgba($black, 0.15);
    color: rgba($white, 0.85);

    &:hover {
      z-index: 1081 !important;
    }

    &:not(.alert-info):not(.alert-success):not(.alert-warning):not(
        .alert-danger
      ) {
      background-color: rgba($black, 0.95);

      .alert-notify-close {
        color: $yellow;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    .ni[data-notify='icon'] {
      position: relative;
      top: 4px;
    }

    .alert-title {
      display: block;
      font-size: $font-size-base;
      font-weight: $font-weight-bold;
    }

    .close {
      top: $alert-padding-y !important;
      right: $alert-padding-x !important;
      transform: translateY(0);
    }
    [data-notify='icon'] {
      font-size: 1.25rem;
      margin-right: 1.25rem;
      display: inline-block;
      vertical-align: middle;
    }
  }
  &.primary {
    .col-11.col-sm-4 {
      width: auto !important;
    }
    .alert-success,
    .alert-error {
      background: #fafafa !important;
      border: 1px solid #00000014;
      box-shadow: 0px 8px 16px 0px #00000014;
      color: #52525b;
      .close {
        > span {
          color: #292d32;
        }
      }
      .alert-text {
        display: flex;
        gap: 8px;
        .alert-text-inner {
          display: flex;
          flex-direction: column;
          .alert-title {
            font-size: 14px;
            line-height: 20px;
            font-weight: 500;
            color: #18181b;
            & + span {
              font-size: 14px;
              line-height: 21px;
              color: #52525b;
              font-weight: 400;
            }
          }
        }
      }
    }
    .alert-error {
      border-color: red;
    }
  }
}
