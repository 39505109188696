@import 'src/assets/scss/colors.scss';

.headerRow {
  display: flex;
  align-items: center;
  gap: 8px;
}
.wrapper {
  table {
    td,
    th {
      border: 1px solid $primary;
      padding: 4px;
    }
  }
}
