@import 'src/assets/scss/colors.scss';

.form {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.formInner {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 646px;
}
.moreFieldsWrapper {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
}
