@import 'src/assets/scss/colors.scss';

.wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: $primary;
  border: 1px solid $primary;
  color: white;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  padding: 4px 12px;
  border-radius: 24px;
  gap: 4px;
  &.variant_green {
    background: $success-0-5;
    color: $success-3;
    border: 1px solid $success-3;
  }
  &.variant_red {
    background: $error-0-5;
    color: $error-3;
    border: 1px solid $error-3;
  }
  &.variant_orange {
    background: $warning-0-5;
    color: $warning-3;
    border: 1px solid $warning-3;
  }
  &.variant_location {
    font-size: 14px;
    line-height: 20px;
    padding: 4px 8px;
    background: #9ca99c29;
    border: 1px solid #082a1580;
    border-radius: 4px;
    color: $black;
  }
  &.variant_warning {
    background: $warning-3;
    border-color: $warning-3;
    color: $white;
    &.ghost {
      background: $warning-0-5;
      color: $warning-3;
    }
  }
  &.variant_error {
    border-color: $error-3;
    background: $error-3;
    color: $white;
    &.ghost {
      background: $error-0-5;
      color: $error-3;
    }
  }
  &.variant_gray {
    &.outline {
      border-color: #e4e4e7;
      background: #f4f4f5;
      color: $neutrals-8;
    }
  }
  &.variant_dark_gray {
    &.outline {
      border-color: $primary-button;
      background: $primary-0-5;
      color: $primary-button;
    }
  }
  &.variant_blue {
    &.outline {
      border-color: $info-3;
      background: $info-0-5;
      color: $info-3;
    }
  }
}

.statusTag {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  border-radius: 24px;
  border: 1px solid $black-3;
  background: transparent;
  color: $black-3;
  &.color_green {
    color: #45b942;
    border-color: #45b942;
    background: #45b9421a;
  }
  &.color_red {
    color: #ff0000d9;
    border-color: #ff0000d9;
    background: #ff000003;
  }
  &.size_sm1 {
    font-size: 12px;
    line-height: 14px;
  }
}
.wrapper,
.statusTag {
  &.weight_lg {
    font-weight: 600;
  }
  &.weight_md {
    font-weight: 500;
  }
  &.radius_sm {
    border-radius: 6px;
  }
  &.size_sm {
    padding-left: 6px;
    padding-right: 6px;
  }
  &.size_sm1 {
    padding: 2px 6px;
  }
}
