@import 'src/assets/scss/colors.scss';

body {
  font-family: 'Inter';
  color: $black-1;
  overflow: hidden;
}
#root {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
}
a {
  &:hover {
    color: $primary;
  }
}
.hide {
  display: none !important;
}
.pointer {
  cursor: pointer !important;
}
.flex {
  display: flex !important;
}
.inline-flex {
  display: inline-flex !important;
}
.inline-block {
  display: inline-block !important;
}

.s-form {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.s-form-inner {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 646px;
}

.btn + .btn {
  margin-left: 0;
}
.btn:not(:last-child) {
  margin-right: 0;
}
// Alert
.rna-wrapper .col-11.col-sm-4 {
  display: block !important;
  // text-align: center;
}
.rna-wrapper .alert {
  width: auto !important;
  display: inline-block !important;
  margin: 1rem 0 0 0 !important;
  padding: 12px 40px 12px 12px !important;
  min-width: 51% !important;
  .close {
    margin-top: 1px !important;
    top: 0 !important;
    bottom: 0 !important;
    right: 14px !important;
    > span {
      font-weight: 300 !important;
      color: #292d32 !important;
      font-size: 24px !important;
    }
  }
  &.alert-success {
    border-color: $success-3 !important;
  }
}
.alert-text {
  .alert-text-inner {
    display: flex;
    flex-direction: row !important;
    gap: 8px;
    align-items: center;
    > svg {
      width: 24px;
      height: 24px;
    }
  }
}

// React-select
.react-select-group-heading-down {
  .icon-wrapper {
    transform: rotate(180deg);
  }
}
