.wrapper {
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    gap: 8px;
    li {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 12px;
      line-height: 20px;
      color: #262726;
      font-weight: 400;
      a {
        color: #262726;
      }
      span {
        font-weight: 600;
      }
    }
  }
}
