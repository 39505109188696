@import 'src/assets/scss/colors.scss';

.wrapper {
  display: flex;
  > div {
    display: flex;
    gap: 64px;
  }
  :global {
    .custom-toggle {
      width: 44px;
      height: 24px;
      .custom-toggle-slider {
        border-color: rgba($black, 0.2);
        background: #e4e4e7;
        box-shadow: 0px 1px 1px 0px rgba($black, 0.1) inset;
      }
    }
    .custom-toggle input:checked + .custom-toggle-slider:before,
    .custom-toggle input + .custom-toggle-slider:before {
      background: white;
    }
    .custom-toggle input:checked + .custom-toggle-slider {
      background: $primary-button;
      border-color: $primary-button;
    }
    .custom-toggle input:checked + .custom-toggle-slider:before {
      transform: translateX(20px);
    }
  }
  &.disabled {
    .label {
      & + span {
        opacity: 0.5;
      }
    }
  }
}
.label {
  display: flex;
  flex-direction: column;
  gap: 8px;
  > span {
    color: $black-2;
    &:nth-child(1) {
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
    }
    &:nth-child(2) {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
    }
  }
  & + span {
    display: flex;
    height: 24px;
  }
}
