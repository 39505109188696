.pageTitle {
  > div:nth-child(2) {
    max-width: 810px;
  }
}
.confirmModalWrapper {
  &:global(.modal-sm) {
    max-width: 456px;
  }
}
