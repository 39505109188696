@import 'src/assets/scss/colors.scss';

.wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: space-between;
}
.actions {
  display: flex;
  align-items: center;
  gap: 16px;
}
.title {
  font-size: 22px;
  font-weight: 700;
  line-height: 150%;
  color: $black-1;
}
