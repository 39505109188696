.wrapper {
  > span {
    padding: 0 16px 16px 16px;
    width: 100%;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 4px;
    display: block;
    font-weight: 600;
  }
}
