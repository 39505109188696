.wrapper {
  border: 1px solid #d8d8d8;
  background: white;
  border-radius: 6px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  > div {
    display: flex;
    font-size: 14px;
    line-height: 20px;
    color: black;
    &:nth-child(1) {
      align-items: center;
      justify-content: space-between;
      font-weight: 600;
      svg {
        cursor: pointer;
      }
    }
    &:nth-child(2) {
      white-space: pre-wrap;
    }
  }
}
.editTag {
  border: 1px solid #e4e4e7;
  background: #f4f4f5;
  padding: 1px 4px;
  border-radius: 6px;
  font-size: 10px;
  font-weight: 500;
  color: #52525b;
  line-height: 16px;
  cursor: pointer;
}
