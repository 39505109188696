.groups {
  vertical-align: top;
  padding-top: 61px;
  min-width: 270px;
  padding-right: 20px;
}
.group {
  background-color: white;
  margin-bottom: 2px;
  padding: 4px;
  text-overflow: ellipsis;
  height: 30px;
  overflow: hidden;
}
.checkbox {
  display: inline-block;
  margin: 1px 1px 4px 4px;
}
a {
  line-height: 26px;
}
