.wrapper {
  display: inline-flex;
  .icon,
  .activeIcon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    > svg {
      width: 100%;
      height: 100%;
    }
  }
  .activeIcon {
    display: none;
  }
  &:hover {
    .icon {
      display: none;
    }
    .activeIcon {
      display: inline-flex;
    }
  }
  &.hasClick {
    .icon,
    .activeIcon {
      cursor: pointer;
    }
  }
}
