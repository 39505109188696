@import 'src/assets/scss/colors.scss';
@import 'src/assets/scss/mixins.scss';

.wrapper {
  display: flex;
  flex-direction: column;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $neutrals-0-5;
  > span {
    font-weight: 600;
    color: $black;
    font-size: 14px;
    line-height: 150%;
    display: inline-block;
    @include more-text(1);
  }
  > div {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
.content {
  > div {
    &:nth-child(1) {
      display: flex;
      height: 24px;
      min-height: 24px;
      width: 100%;
    }
    &:nth-child(2) {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 24px;
    }
  }
  .addBtn {
    align-items: center;
  }
  &.isNotFound {
    > div:nth-child(2) {
      grid-template-columns: repeat(1, 1fr);
    }
    .addBtn {
      align-items: flex-start;
      justify-content: center;
    }
  }
}
