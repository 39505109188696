.input {
  background: transparent !important;
  border: 0 !important;
  height: 0 !important;
  color: transparent !important;
  box-shadow: none !important;
  padding: 0 !important;
  margin: 0 !important;
  position: fixed !important;
  left: -99999px !important;
}
