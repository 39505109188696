@import 'src/assets/scss/colors.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  &.hideHeaderBorder {
    .header {
      .headerInner {
        border: 0;
        .headerItem {
          > span {
            border: 0;
          }
        }
      }
    }
    .content {
      .tabContent {
        padding-top: 4px;
      }
    }
  }
  &.only1Header {
    .header {
      .headerInner {
        .headerItem {
          > span {
            padding-left: 0;
          }
        }
      }
    }
  }
  &.headerFullWidth {
    .header {
      .headerInner {
        width: 100%;
      }
    }
  }
  &.headerSize_sm {
    .headerItem {
      > span {
        font-size: 16px;
        line-height: 20px;
        padding: 12px;
      }
    }
  }
}
.header {
  display: flex;
  position: relative;
  .headerInner {
    display: inline-flex;
    border-bottom: 1px solid $gray-3;
  }
  .headerExtra {
    margin-left: auto;
    position: absolute;
    right: 0;
    top: 14px;
  }
}
.headerItem {
  display: flex;
  > span {
    display: flex;
    padding: 8px 16px;
    font-size: 24px;
    line-height: 29px;
    color: $neutrals-8;
    font-weight: 400;
    border-bottom: 2px solid transparent;
    cursor: pointer;
  }
  &.active,
  &:hover {
    > span {
      border-color: $primary-button;
      color: $primary-button;
    }
  }
  &.active {
    > span {
      font-weight: 600;
    }
  }
}
.content {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.tabContent {
  display: none;
  flex: 1;
  &.active {
    display: flex;
    flex-direction: column;
    padding: 16px 0;
  }
}
