.wrapper {
  position: relative;
  input {
    border-radius: 0.25rem !important;
    z-index: 1;
  }
  .suffix {
    position: absolute;
    right: 2px;
    top: 1px;
    bottom: 1px;
    height: 42px;
    margin: 0;
    z-index: 4;
    &.withLabel {
      top: 26px;
    }
  }
}
.eyeIcon {
  display: inline-flex;
  cursor: pointer;
}
