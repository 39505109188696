@import 'src/assets/scss/colors.scss';

.emptyTab {
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  padding-top: 80px;
  > span {
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    color: $black;
  }
}
