@import 'src/assets/scss/colors.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  border: 1px solid $gray-3;
  border-radius: 6px;
  background: white;
  :global {
    .table {
      margin-bottom: 0;
    }
  }
  &.unbox {
    border: 0;
    background: transparent;
  }
  &.tableBox {
    .inner {
      background: $white;
      border: 1px solid $gray-2;
      border-radius: 6px;
      :global {
        .table-responsive {
          border-radius: 6px 6px 0 0;
          thead {
            tr {
              th {
                border-top: 0;
              }
            }
          }
        }
      }
    }
  }
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  > span {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: black;
  }
  > div {
    display: flex;
    align-items: center;
    gap: 16px;
    > div {
      &:nth-child(1) {
        width: 232px;
      }
      &:nth-child(2) {
        width: 118px;
      }
      &:nth-child(3) {
      }
    }
  }
}
.inner {
  display: flex;
  flex-direction: column;
}
