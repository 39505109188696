@import 'src/assets/scss/colors.scss';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  font-size: 11px;
  font-weight: 700;
  line-height: 20px;
  color: $black;
  background: $neutrals-1;
  padding: 0px 8px;
  &.type_error {
    background: $error-3;
    color: $white;
  }
  &.type_warning {
    background: $warning-3;
    color: $white;
  }
  &.min {
    min-width: 20px;
    width: 20px;
    height: 20px;
    padding: 0;
  }
}
