@import 'src/assets/scss/colors.scss';

:global {
  .modal-backdrop.show {
    opacity: 0.5;
  }
}
.wrapper {
  &:global(.modal-dialog) {
    :global {
      .modal-content {
        border-radius: 8px;
      }
      .modal-header {
        border-bottom: 1px solid #e4e4e7;
        border-radius: 8px 8px 0 0;
        padding: 12px 24px;
        .modal-title {
          font-size: 16px;
          font-weight: 600;
          color: $black-2;
          line-height: 28px;
        }
      }
      .modal-body {
        padding: 16px 24px;
      }
    }
    &:global(.modal-s_small) {
      max-width: 458px;
    }
    &:global(.modal-large) {
      max-width: 915px;
    }
  }
  &.radius_lg {
    &:global(.modal-dialog) {
      :global {
        .modal-content {
          border-radius: 16px;
        }
        .modal-header {
          border-radius: 16px 16px 0 0;
        }
      }
    }
  }
  &.fontSize_sm {
    .confirmModalContent {
      font-size: 14px;
      line-height: 20px;
    }
  }
  &.deleteModalWrapper,
  &.confirmModalWrapper {
    &:global(.modal-s_small) {
      font-size: 14px;
      line-height: 20px;
    }
  }
}
.confirmModalFooter {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 24px;
}
.deleteModalFooter {
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}
.deleteDescription {
  font-size: 14px;
  line-height: 20px;
  color: $black-2;
  margin-bottom: 16px;
}
