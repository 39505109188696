@import 'src/assets/scss/colors.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  background: white;
  border: 1px solid #d8d8d8;
  border-radius: 6px;
  padding: 16px;
  color: black;
  gap: 16px;
  box-shadow: 0px 2px 4px 0px #0000000a;
  &.isIssue {
    gap: 20px;
  }
  &.isLocation {
    .scoreContent {
      display: flex;
      flex-direction: row;
      gap: 40px;
    }
    .score {
      height: 130px;
    }
  }
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  > span {
    font-size: 20px;
    font-weight: 600;
    color: $black-1;
    line-height: 24px;
  }
}
.content {
  width: 100%;
  height: 186px;
  display: flex;
  align-items: center;
  justify-content: center;
  // border: 1px solid #f5f5f5;
  // box-shadow: 0px 2px 4px 0px #dfdfdf40;
  border-radius: 4px;
  padding: 16px;
  > div {
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    align-items: center;
    > span {
      font-size: 12px;
      font-weight: 600;
      line-height: 14px;
    }
  }
}
.description {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 57px;
}
.subHeading {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: $black-2;
}
.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  > span {
    display: flex;
    align-items: center;
    gap: 4px;
    &:nth-child(1) {
      font-size: 14px;
      line-height: 17px;
      svg {
        width: 16px;
        height: 16px;
      }
    }
    &:nth-child(2) {
      font-size: 16px;
      line-height: 19px;
      font-weight: 500;
      cursor: pointer;
    }
  }
}
.scoreContent {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
}
.score {
  width: 226px;
  margin: 0 auto;
}
.scoreReport {
  margin-top: auto;
}
.issueContent {
  display: flex;
  flex-direction: column;
  gap: 20px;
  canvas {
    width: 260px !important;
    height: 260px !important;
    margin: 0 auto;
  }
}
.issueLegends {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 12px;
  > span {
    display: inline-flex;
    height: 16px;
    align-items: center;
    gap: 6px;
    > span {
      &:nth-child(1) {
        height: 4px;
        width: 12px;
        border-radius: 12px;
        background: $primary;
      }
      &:nth-child(2) {
        color: #b9b9b9;
        font-size: 12px;
        line-height: 12px;
        font-weight: 400;
      }
      &:nth-child(3) {
        font-size: 12px;
        line-height: 12px;
        font-weight: 700;
        color: black;
      }
    }
  }
}
.locationScoreRight {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 16px;
  > div {
    &:nth-child(2) {
      display: flex;
      flex-direction: column;
      gap: 16px;
      > div {
        display: flex;
        justify-content: space-between;
        padding-right: 16px;
        &:nth-child(1) {
          display: block;
        }
      }
    }
  }
}
